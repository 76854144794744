var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "full" } },
    [
      _c(
        "vs-breadcrumb",
        { staticClass: "mb-3", attrs: { separator: "chevron_right" } },
        [
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "vs-breadcrumb--link",
                  attrs: {
                    to: `/applications/${_vm.applicationId}/currencyExchange`,
                  },
                },
                [_vm._v("Currency Exchange")]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "separator notranslate vs-breadcrum--separator material-icons",
                  attrs: { "aria-hidden": "true" },
                },
                [_vm._v("\n        chevron_right\n      ")]
              ),
            ],
            1
          ),
          _c("li", [
            _c(
              "span",
              { staticClass: "vs-breadcrumb--text vs-breadcrumb-text-primary" },
              [_vm._v("Currency Manager")]
            ),
          ]),
        ]
      ),
      _c("h2", { staticClass: "mb-4", staticStyle: { color: "#636363" } }, [
        _vm._v("Currency Manager"),
      ]),
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4 sm:mb-0 mb-2",
                    attrs: { color: "primary", type: "filled" },
                    on: { click: _vm.onAddCurrency },
                  },
                  [_vm._v("Add")]
                ),
                _c("vs-input", {
                  staticClass:
                    "float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2",
                  attrs: { placeholder: "Search..." },
                  on: { input: _vm.updateSearchQuery },
                  model: {
                    value: _vm.searchQuery,
                    callback: function ($$v) {
                      _vm.searchQuery = $$v
                    },
                    expression: "searchQuery",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            attrs: {
              components: _vm.cellRendererComponents,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.currencyList,
              gridOptions: _vm.gridOptions,
              autoGroupColumnDef: _vm.autoGroupColumnDef,
              rowDragManaged: true,
              animateRows: true,
              rowSelection: "single",
              pagination: false,
              context: _vm.context,
              suppressPaginationPanel: true,
            },
            on: {
              "first-data-rendered": _vm.onFirstDataRendered,
              "grid-ready": _vm.onGridReady,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }